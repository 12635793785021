<template>
  <div class="bg-white p-20 rounded">
    <div>
      <a-table
        :pagination="false"
        rowKey="id"
        :dataSource="dataSource"
        :columns="columns"
      >
        <span slot="index" slot-scope="text, record, index">{{
          index + 1 + limit * (current - 1)
        }}</span>
        <span slot="time" slot-scope="text">
          {{ text | formatterTime("YYYY-MM-DD") }}
        </span>
        <a-space slot="action" slot-scope="text">
          <router-link :to="`/user/survey-detail/${text}`"
            >查看详情</router-link
          >
        </a-space>
      </a-table>
      <div class="text-center mt-20">
        <a-pagination
          :pageSize="limit"
          :total="total"
          v-model="current"
          @change="change"
        ></a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionList } from "@/api1/common";
export default {
  data() {
    return {
      status: 2,
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "index" },
        },
        {
          dataIndex: "name",
          title: "问卷名称",
        },
        {
          dataIndex: "time",
          title: "问卷日期",
          scopedSlots: { customRender: "time" },
        },
        {
          dataIndex: "count",
          title: "样本数据",
        },
        {
          dataIndex: "id",
          title: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      total: 0,
      current: 1,
      limit: 10,
    };
  },
  created() {
    this.getQuestionList();
  },
  methods: {
    change(e) {
      this.current = e;
      const temp = {
        status: this.status,
        limit: this.limit,
        offset: (this.current - 1) * this.limit,
      };
      this.getQuestionList(this.company, temp);
    },
    getQuestionList() {
      getQuestionList()
        .then(({ data }) => {
          const { count, rows } = data;
          this.total = count;
          const temp = rows || [];
          this.dataSource = temp.map((item) => {
            const detail = item.Detail || {};
            const stats = item.Stats || {};
            const temp = {
              name: detail.Name,
              time: new Date(item.CreatedAt).getTime(),
              count: stats.Count,
              id: item.ID,
            };
            return temp;
          });
        })
        .catch((err) => {
          this.$message.error("获取关联列表失败");
        });
    },
  },
};
</script>